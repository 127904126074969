<script setup>
import {IconProps, useIcon} from './useIcon.js'

const props = defineProps(IconProps(15,15));

const { color } = useIcon(props);
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="props.width" :height="props.height" viewBox="0 0 15 15" fill="none" :style="{color: color}">
        <path d="M7.49987 10.2798C7.08052 10.2798 6.729 10.6313 6.729 11.0507C6.729 11.47 7.08052 11.8215 7.49987 11.8215C7.90381 11.8215 8.27074 11.47 8.25224 11.0692C8.27074 10.6282 7.92231 10.2798 7.49987 10.2798Z" fill="currentColor"/>
        <path d="M14.635 13.0116C15.1191 12.176 15.1222 11.1801 14.6412 10.3475L9.81243 1.98511C9.33449 1.14332 8.47111 0.643799 7.5029 0.643799C6.53468 0.643799 5.67131 1.14641 5.19337 1.98203L0.35847 10.3537C-0.122554 11.1955 -0.11947 12.1976 0.36772 13.0332C0.848743 13.8596 1.70903 14.356 2.67108 14.356H12.3162C13.2813 14.356 14.1478 13.8534 14.635 13.0116ZM13.5866 12.4073C13.3183 12.8698 12.8435 13.1442 12.3131 13.1442H2.668C2.14381 13.1442 1.67203 12.876 1.40994 12.4227C1.14476 11.9633 1.14167 11.4144 1.40685 10.9519L6.24175 2.58331C6.50385 2.12387 6.97254 1.85252 7.5029 1.85252C8.03017 1.85252 8.50195 2.12695 8.76404 2.58639L13.5959 10.955C13.8549 11.4052 13.8518 11.9478 13.5866 12.4073Z" fill="currentColor"/>
        <path d="M7.30861 4.8682C6.94168 4.97304 6.7135 5.30605 6.7135 5.70999C6.732 5.95358 6.74742 6.20026 6.76592 6.44386C6.81834 7.37199 6.87076 8.28161 6.92318 9.20974C6.94168 9.52426 7.18527 9.75243 7.49979 9.75243C7.8143 9.75243 8.06098 9.50884 8.0764 9.19124C8.0764 9.00006 8.0764 8.82431 8.0949 8.63005C8.12882 8.03493 8.16582 7.43982 8.19974 6.84471C8.21824 6.45927 8.25216 6.07384 8.27066 5.6884C8.27066 5.54965 8.25216 5.42631 8.19974 5.30297C8.04248 4.95762 7.67555 4.78186 7.30861 4.8682Z" fill="currentColor"/>
    </svg>
</template>

<style scoped>

</style>