<script setup>

import Modal from "@/Components/Modal/Modal.vue";
import Button from "@/Components/Inputs/Button.vue";
import {computed, reactive, ref, watch} from "vue";
import { useRouter } from 'vue-router'
import Table from "@/Components/Table/Table.vue";
import TheadTh from "@/Components/Table/TheadTh.vue";
import Tbody from "@/Components/Table/Tbody.vue";
import TbodyTr from "@/Components/Table/TbodyTr.vue";
import Thead from "@/Components/Table/Thead.vue";
import TbodyTd from "@/Components/Table/TbodyTd.vue";
import TheadTr from "@/Components/Table/TheadTr.vue";
import Icon from "@/Icons/Icon.vue"
import Checkbox from "@/Components/Inputs/Checkbox.vue"

const props = defineProps({
    show: Boolean,
    table: Object
});

const emit = defineEmits(['close']);

const show = ref(props.show);

const close = () => {
    show.value = false;
    emit('close');
}

watch(() => props.show, (val) => {
    show.value = props.show;
});

const importData = computed(() => {
    return props.table.getImportPriceListData();
});

const showBelowMinPrice = ref(false);
const showZeroPrice = ref(false);

const filteredImportData = computed(() => {
    const data = importData.value;

    return data.filter((row) => {
        let isBelowMinPrice = false;
        let isZeroPrice = false;

        isBelowMinPrice = (row.NewPrice !== null && row.NewPrice >0 && row.MinPrice !== null && row.NewPrice < row.MinPrice) || (row.NewDeposit !== null && row.NewDeposit > 0 && row.MinPriceDeposit !== null && row.NewDeposit < row.MinPriceDeposit);
        isZeroPrice = row.NewPrice === 0 || row.NewDeposit === 0;

        if(showBelowMinPrice.value && showZeroPrice.value){
            return isBelowMinPrice || isZeroPrice;
        }else if(showBelowMinPrice.value){
            return isBelowMinPrice;
        }else if(showZeroPrice.value){
            return isZeroPrice;
        }else{
            return true;
        }
    });
});

const statistics = computed(() => {
    const data = importData.value;
    const totalItems = data.length;

    // Price statistiky
    let highestPriceIncrease = null;
    let highestPriceDecrease = null;
    let totalPriceIncreaseAmount = 0;
    let priceIncreaseCount = 0;
    let totalPriceDecreaseAmount = 0;
    let priceDecreaseCount = 0;
    let newPricePositiveCount = 0;

    // Deposit statistiky
    let highestDepositIncrease = null;
    let highestDepositDecrease = null;
    let totalDepositIncreaseAmount = 0;
    let depositIncreaseCount = 0;
    let totalDepositDecreaseAmount = 0;
    let depositDecreaseCount = 0;
    let newDepositPositiveCount = 0;

    let belowMinPriceCount = 0;
    let belowMinPriceDepositCount = 0;
    let newPriceZeroCount = 0;
    let newDepositZeroCount = 0;

    for (const item of data) {
        // Price calculations
        const priceIncrease = item.NewPrice !== null ? (item.NewPrice - (item.CurrentPrice ?? 0)) / 100 : null;
        const priceDecrease = item.NewPrice !== null ? ((item.CurrentPrice ?? 0) - item.NewPrice) / 100 : null;

        if (priceIncrease !== null && priceIncrease > 0) {
            highestPriceIncrease = highestPriceIncrease === null ? priceIncrease : Math.max(highestPriceIncrease, priceIncrease);
            totalPriceIncreaseAmount += priceIncrease;
            priceIncreaseCount++;
        }

        if (priceDecrease !== null && priceDecrease > 0) {
            highestPriceDecrease = highestPriceDecrease === null ? priceDecrease : Math.max(highestPriceDecrease, priceDecrease);
            totalPriceDecreaseAmount += priceDecrease;
            priceDecreaseCount++;
        }

        if (item.NewPrice !== null && item.NewPrice > 0) {
            newPricePositiveCount++;
        }

        // Deposit calculations
        const depositIncrease = item.NewDeposit !== null ? item.NewDeposit - (item.CurrentPriceDeposit ?? 0) : null;
        const depositDecrease = item.NewDeposit !== null ? (item.CurrentPriceDeposit ?? 0) - item.NewDeposit : null;

        if (depositIncrease !== null && depositIncrease > 0) {
            highestDepositIncrease = highestDepositIncrease === null ? depositIncrease : Math.max(highestDepositIncrease, depositIncrease);
            totalDepositIncreaseAmount += depositIncrease;
            depositIncreaseCount++;
        }

        if (depositDecrease !== null && depositDecrease > 0) {
            highestDepositDecrease = highestDepositDecrease === null ? depositDecrease : Math.max(highestDepositDecrease, depositDecrease);
            totalDepositDecreaseAmount += depositDecrease;
            depositDecreaseCount++;
        }

        if (item.NewDeposit !== null && item.NewDeposit > 0) {
            newDepositPositiveCount++;
        }

        // Other statistics
        if (item.NewPrice !== null && item.NewPrice > 0 && item.MinPrice !== null && item.NewPrice < item.MinPrice) {
            belowMinPriceCount++;
        }

        if (item.NewDeposit !== null && item.NewDeposit > 0 && item.MinPriceDeposit !== null && item.NewDeposit < item.MinPriceDeposit) {
            belowMinPriceDepositCount++;
        }

        if (item.NewPrice === 0) {
            newPriceZeroCount++;
        }

        if (item.NewDeposit === 0) {
            newDepositZeroCount++;
        }
    }

    const averagePriceIncrease = (priceIncreaseCount > 0 ? totalPriceIncreaseAmount / priceIncreaseCount : 0).toFixed(1);
    const averagePriceDecrease = (priceDecreaseCount > 0 ? totalPriceDecreaseAmount / priceDecreaseCount : 0).toFixed(1);
    const averageDepositIncrease = (depositIncreaseCount > 0 ? totalDepositIncreaseAmount / depositIncreaseCount : 0).toFixed(1);
    const averageDepositDecrease = (depositDecreaseCount > 0 ? totalDepositDecreaseAmount / depositDecreaseCount : 0).toFixed(1);

    return {
        totalItems: totalItems,
        price: {
            highestIncrease: highestPriceIncrease,
            highestDecrease: highestPriceDecrease,
            averageIncrease: averagePriceIncrease,
            averageDecrease: averagePriceDecrease,
            belowMinPriceCount: belowMinPriceCount,
            newPriceZeroCount: newPriceZeroCount,
            newPricePositiveCount: newPricePositiveCount,
        },
        deposit: {
            highestIncrease: highestDepositIncrease,
            highestDecrease: highestDepositDecrease,
            averageIncrease: averageDepositIncrease,
            averageDecrease: averageDepositDecrease,
            belowMinPriceCount: belowMinPriceDepositCount,
            newDepositZeroCount: newDepositZeroCount,
            newDepositPositiveCount: newDepositPositiveCount,
        },
    };
});

</script>

<template>
    <Modal :isVisible="show" @update:isVisible="close" size="large">
        <template #header>
            Import ceníku ({{importData.length}} z {{table.sheet.data.length}})
        </template>
        <template #body>
            <div>
                <div class="tw-mb-1">
                    Ceník: {{ table.configurator.Settings.type === 'group' ? table.configurator.Settings.groupPrice.text : table.configurator.Settings.eshop.text }}
                </div>
                <i>* Importují se pouze vyfiltrované položky viz seznam dole</i>
                <div class="tw-flex tw-mt-3 tw-mb-2">
                    <Checkbox v-model="showBelowMinPrice" label="Zobrazit položky s cenou pod MinPrice" class="tw-mr-6" />
                    <Checkbox v-model="showZeroPrice" label="Zobrazit položky s 0" />
                </div>

                <Table class="tw-max-h-[650px] tw-overflow-auto">
                    <Thead>
                        <TheadTr>
                            <TheadTh>ID</TheadTh>
                            <TheadTh>Název produktu</TheadTh>
                            <TheadTh>RMT kód</TheadTh>
                            <TheadTh>Nová cena ({{ table.configurator.Settings.currency }})</TheadTh>
                            <TheadTh>Nový deposit ({{ table.configurator.Settings.currency }})</TheadTh>
                        </TheadTr>
                    </Thead>
                    <Tbody>
                        <TbodyTr v-for="(row, index) in filteredImportData" :key="index"
                                 :class="{
                                    '!tw-bg-red-300': (row.NewPrice !== null && row.NewPrice >0 && row.MinPrice !== null && row.NewPrice < row.MinPrice) || (row.NewDeposit !== null && row.NewDeposit > 0 && row.MinPriceDeposit !== null && row.NewDeposit < row.MinPriceDeposit)
                                }"
                        >
                            <TbodyTd>
                                <div class="tw-flex tw-items-center">
                                    <Icon icon="danger" color="red" class="tw-mr-2" v-if="(row.NewPrice !== null && row.NewPrice >0 && row.MinPrice !== null && row.NewPrice < row.MinPrice) || (row.NewDeposit !== null && row.NewDeposit > 0 && row.MinPriceDeposit !== null && row.NewDeposit < row.MinPriceDeposit)"></Icon>
                                    <Icon icon="delete" color="red" class="tw-mr-2" width="16" v-if="row.NewPrice === 0 || row.NewDeposit === 0"></Icon>
                                    {{ row.ID }}
                                </div>

                            </TbodyTd>
                            <TbodyTd>
                                {{ row.ProductName }}
                            </TbodyTd>
                            <TbodyTd>
                                {{ row.RmtCode }}
                            </TbodyTd>
                            <TbodyTd>
                                {{ row.NewPrice === null ? '-' : row.NewPrice / 100 }}
                                <span v-if="row.NewPrice !== null && row.CurrentPrice !== null &&  row.NewPrice < row.CurrentPrice" class="tw-text-green-600">(▼ {{ (((row.CurrentPrice - row.NewPrice) / row.CurrentPrice)*100).toFixed(2) }}%)</span>
                                <span v-if="row.NewPrice !== null && row.CurrentPrice !== null && row.NewPrice > row.CurrentPrice" class="tw-text-red-600">(▲ {{ (((row.NewPrice - row.CurrentPrice) / row.CurrentPrice)*100).toFixed(2) }}%)</span>
                                <span v-if="row.NewPrice !== null && row.CurrentPrice !== null && row.NewPrice === row.CurrentPrice" class="tw-text-gray-600">(►)</span>
                            </TbodyTd>
                            <TbodyTd>
                                {{ row.NewDeposit === null ? '-' : row.NewDeposit / 100 }}
                                <span v-if="row.NewDeposit !== null && row.CurrentPriceDeposit !== null &&  row.NewDeposit < row.CurrentPriceDeposit" class="tw-text-green-600">(▼ {{ (((row.CurrentPriceDeposit - row.NewDeposit) / row.CurrentPriceDeposit)*100).toFixed(2) }}%)</span>
                                <span v-if="row.NewDeposit !== null && row.CurrentPriceDeposit !== null && row.NewDeposit > row.CurrentPriceDeposit" class="tw-text-red-600">(▲ {{ (((row.NewDeposit - row.CurrentPriceDeposit) / row.CurrentPriceDeposit)*100).toFixed(2) }}%)</span>
                                <span v-if="row.NewDeposit !== null && row.CurrentPriceDeposit !== null && row.NewDeposit === row.CurrentPriceDeposit" class="tw-text-gray-600">(►)</span>
                            </TbodyTd>
                        </TbodyTr>
                    </Tbody>
                </Table>
                <div class="tw-mt-2">
                    <h2>Statistiky</h2>
                    <div style="display: flex; gap: 50%" class="tw-mt-4">
                        <!-- Price statistiky -->
                        <div>
                            <b>Price</b>
                            <ul class="tw-mt-2">
                                <li><b>Celkový počet položek:</b>  {{ statistics.totalItems }}</li>
                                <li><b>Nejvyšší zdražení:</b>  {{ statistics.price.highestIncrease ?? 0 }} {{ table.configurator.Settings.currency }}</li>
                                <li><b>Nejvyšší zlevnění:</b>  {{ statistics.price.highestDecrease ?? 0 }} {{ table.configurator.Settings.currency }}</li>
                                <li><b>Průměrné zdražení:</b>  {{ statistics.price.averageIncrease ?? 0 }} {{ table.configurator.Settings.currency }}</li>
                                <li><b>Průměrné zlevnění:</b>  {{ statistics.price.averageDecrease ?? 0 }} {{ table.configurator.Settings.currency }}</li>
                                <li><b>Ceny pod MinPrice:</b>  {{ statistics.price.belowMinPriceCount }}</li>
                                <li><b>Počet položek s NewPrice > 0:</b>  {{ statistics.price.newPricePositiveCount }}</li>
                                <li><b>Počet položek s NewPrice 0:</b>  {{ statistics.price.newPriceZeroCount }}</li>
                            </ul>
                        </div>

                        <!-- Deposit statistiky -->
                        <div>
                            <b>Deposit</b>
                            <ul class="tw-mt-2">
                                <li><b>Celkový počet položek:</b>  {{ statistics.totalItems }}</li>
                                <li><b>Nejvyšší zdražení:</b>  {{ statistics.deposit.highestIncrease ?? 0 }} {{ table.configurator.Settings.currency }}</li>
                                <li><b>Nejvyšší zlevnění:</b>  {{ statistics.deposit.highestDecrease ?? 0 }} {{ table.configurator.Settings.currency }}</li>
                                <li><b>Průměrné zdražení:</b>  {{ statistics.deposit.averageIncrease ?? 0 }} {{ table.configurator.Settings.currency }}</li>
                                <li><b>Průměrné zlevnění:</b>  {{ statistics.deposit.averageDecrease ?? 0 }} {{ table.configurator.Settings.currency }}</li>
                                <li><b>Ceny pod MinPriceDeposit:</b>  {{ statistics.deposit.belowMinPriceCount }}</li>
                                <li><b>Počet položek s NewDeposit > 0:</b>  {{ statistics.deposit.newDepositPositiveCount }}</li>
                                <li><b>Počet položek s NewDeposit 0:</b>  {{ statistics.deposit.newDepositZeroCount }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <Button variant="red" @click="close">Zavřít</Button>
            <Button  @click="() => {if(table.importPriceList()) close();}" variant="green" icon="send" icon-color="white" icon-direction="left" tooltip="Naimportuje/aktualizuje ceník podle dat z tabulky">Naimportovat nový ceník</Button>
        </template>
    </Modal>
</template>

<style scoped>

</style>