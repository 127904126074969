<script setup>
import {ref} from 'vue';

    // Definice props pro ID, label a modelValue
    const props = defineProps({
        id: String,
        label: String,
        modelValue: Boolean,
        inverted: {
          type: Boolean,
          default: false,
        },
        size: {
            type: String,
            default: 'default'
        }
    });

    // Definice emit pro aktualizaci modelValue
    const emit = defineEmits(['update:modelValue']);

    const inputEl = ref(null); // Reference na input element (pro focus, blur, atd.

    const getChecked = () => {
        return props.inverted ? !props.modelValue : props.modelValue;
    }

    const onChange = (event) => {
        const checked = event.target.checked;
        emit('update:modelValue', props.inverted ? !checked : checked);
    }
</script>

<template>
    <div class="checkbox-wrapper">
        <input ref="inputEl" :id="id" type="checkbox" :checked="getChecked()" @change="onChange">
        <label
            :class="'size-' + size"
            :for="id"
            @click="inputEl.click()"
        >
            {{ label }}
        </label>
    </div>
</template>

<style scoped>
    .checkbox-wrapper{
        display: flex;
        align-items: center;

        input{
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            width: 18px;
            height: 18px;
            min-width: 18px;
            min-height: 18px;
            border: 1px solid #CDD8ED;
            border-radius: 4px; /* Zaoblení okrajů */
            display: inline-block;
            position: relative;
            background-color: #fff;
            cursor: pointer;

            &:checked{
                border-color: #24C586;
                background-color: #24C586;

                &:after{
                    content: '';
                    display: block;
                    width: 4px;
                    height: 8px;
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 2px;
                    left: 5px;
                }
            }


            &:focus {
                outline: none;
            }
        }

        label{
            margin-left: 8px;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 0 !important;

            &.size-small {
                font-size: 12px;
            }
        }
    }
</style>