import {type ITable, Table, type TableState} from "@project/Excel/Handsontable/js/Table";
import axios from "axios";
import {SheetManager} from "@project/Excel/Handsontable/js/SheetManager";
import {useSheetManager} from "@project/Excel/ExcelApp/Hooks/useSheetManager";

export interface IPriceListTable extends ITable {

}

export interface PriceListTableState extends TableState {
    configurator?: any,
    afterLoadConfigurator?: any,
}

export class PriceListTable extends Table implements IPriceListTable{
    public configurator?: any;
    public afterLoadConfigurator?: any;

    constructor(tableState: PriceListTableState | null = null){
        super(tableState);

        if(tableState?.afterLoadConfigurator){
            this.afterLoadConfigurator = tableState.afterLoadConfigurator;
        }

        this.setConfigurator();


    }

    public async init(tableState: TableState | null = null) {
        this.specialRowsCount = 0;

        tableState = {
            ...tableState ?? {},
            handsontableSettings: {
                ...tableState.handsontableSettings ?? {},
                fixedColumnsStart: 3,
            }
        };

        if(this.specialRowsCount > 0){

        }

        super.init(tableState);
    }



    async setConfigurator(){
        if(this.sheet && this.sheet.id){
            this.configurator = await PriceListTable.loadPriceListConfigurator(this.sheet.id);
            if(typeof this.afterLoadConfigurator === 'function'){
                this.afterLoadConfigurator(this.configurator);
            }
        }
    }

    getImportPriceListData(){
        let data = this.getCalculatedData();

        const productIDcol = this.sheet.settings.sheetDefinition.getColumnByAlias('_Product_ID');
        const productNameCol = this.sheet.settings.sheetDefinition.getColumnByAlias('_ProductName');
        const rmtCodeCol = this.sheet.settings.sheetDefinition.getColumnByAlias('_RmtCode');
        const currentPriceCol = this.sheet.settings.sheetDefinition.getColumnByAlias('_CurrentPrice');
        const currentPriceDepositCol = this.sheet.settings.sheetDefinition.getColumnByAlias('_CurrentPriceDeposit');
        const minPriceCol = this.sheet.settings.sheetDefinition.getColumnByAlias('MinSalePrice');
        const minPriceDepositCol = this.sheet.settings.sheetDefinition.getColumnByAlias('MinSalePriceDeposit');

        data = data.map((row) => {
            if(row.EnableForImport === false || row.EnableForImport === ''){
                return;
            }

            let newPrice = row.NewPrice ?? null;
            if(newPrice === ''){
                newPrice = null;
            }

            let newDeposit = row.NewDeposit ?? null;
            if(newDeposit === ''){
                newDeposit = null;
            }

            let currentPrice = row[currentPriceCol.joinData] ?? null;
            if(currentPrice === ''){
                currentPrice = null;
            }

            let currentDepositPrice = row[currentPriceDepositCol.joinData] ?? null;
            if(currentDepositPrice === ''){
                currentDepositPrice = null;
            }

            let minPrice = minPriceCol ? row[minPriceCol.joinData] ?? null : null;
            if(minPrice === ''){
                minPrice = null;
            }

            let minPriceDeposit = minPriceDepositCol ? row[minPriceDepositCol.joinData] ?? null : null;
            if(minPriceDeposit === ''){
                minPriceDeposit = null;
            }

            if(newPrice !== null || newDeposit !== null){
                return {
                    ID: row[productIDcol.joinData] ?? null,
                    ProductName: row[productNameCol.joinData] ?? null,
                    RmtCode: row[rmtCodeCol.joinData] ?? null,
                    CurrentPrice: currentPrice !== null ? currentPrice * 100 : null,
                    CurrentPriceDeposit: currentDepositPrice !== null ? currentDepositPrice * 100 : null,
                    NewPrice: newPrice !== null ? newPrice * 100 : null,
                    NewDeposit: newDeposit !== null ? newDeposit * 100 : null,
                    MinPrice: minPrice !== null ? minPrice * 100 : null,
                    MinPriceDeposit: minPriceDeposit !== null ? minPriceDeposit * 100 : null,
                };
            }
        });

        data = data.filter((row) => {return typeof row !== 'undefined'});

        // if(!toDelete){
        //     data = data.filter((row) => {return typeof row !== 'undefined'});
        // }

        return data;
    }

    importPriceList(){
        //url
        const url = window.remoteUrl;
        //url

        if(!this.configurator){
            this.alerts.error('Konfigurátor není načtený')
            console.error('Configurator not set');
            return;
        }

        const data = this.getImportPriceListData();

        let minPriceError = false;
        let zeroItemsCount = 0;
        data.forEach((row) => {
           if(row.NewPrice !== null && row.NewPrice > 0 && row.MinPrice !== null && row.NewPrice < row.MinPrice){
               this.alerts.error('Některé položky mají novou cenu nižší než je minimální prodejní cena');
               minPriceError = true;
               return;
           }

            if(row.NewDeposit !== null && row.NewDeposit > 0 && row.MinPriceDeposit !== null && row.NewDeposit < row.MinPriceDeposit){
                this.alerts.error('Některé položky mají novou cenu nižší než je minimální prodejní cena');
                minPriceError = true;
                return;
            }

            if((row.NewPrice !== null && row.NewPrice === 0) || (row.NewDeposit !== null && row.NewDeposit === 0)){
                zeroItemsCount++;
            }
        });

        if(minPriceError){
            return false;
        }

        if(zeroItemsCount > 0){
            const confirm = window.confirm('Některé položky (' + zeroItemsCount + ') mají nulovou cenu. Chcete pokračovat?');
            if(!confirm){
                return false;
            }
        }

        data.map((row) => {
            return {
                ID: row.ID,
                NewPrice: row.NewPrice,
                NewDeposit: row.NewDeposit
            }
        });

        let priceListID = null;
        if(this.configurator.Settings.type === 'group'){
            priceListID = this.configurator.Settings.groupPrice.value;
        }else if(this.configurator.Settings.type === 'eshop'){
            priceListID = this.configurator.Settings.eshop.value;
        }

        const attrs = {
            sheetID: this.sheet.id,
            data: JSON.stringify(data),
            currency: this.configurator.Settings.currency,
            type: this.configurator.Settings.type,
            priceListID: priceListID,
        }

        const {sheetManager}: SheetManager = useSheetManager();
        let result = null;
        this.ajax.postForm(`${url}/default/handsontable/import-price-list`, attrs, null, {
            waitingAlert: {
                title: 'Import ceníku',
            }
        }).then(({response, alert}) => {
            if(response.data === 'OK imported'){
                alert.changeToSuccess('Ceník úspěšně importován');
                this.saveSheetDataToServer();
                sheetManager.logImportPriceList(this.sheet, {
                    importData: data,
                    result: 'OK',
                });
            }else{
                alert.changeToError('Chyba při importu ceníku', response.data ? response.data : '');
                sheetManager.logImportPriceList(this.sheet, {
                    importData: data,
                    result: response.data,
                });
            }





        }).catch(({error, alert}) => {
            alert.changeToError('Chyba při importu ceníku', error.message ? error.message : '', error);

            sheetManager.logImportPriceList(this.sheet, {
                importData: data,
                result: error.message,
            });
        });





        // tableData.forEach((row, index) => {
        //     data[row[0]] = row[1];
        // });
    }

    static async loadPriceListConfigurator(sheet_id: any){
        //url
        const url = window.remoteUrl;
        //url

        if (!sheet_id) return null;

        const data = {
            Sheet_ID: sheet_id,
        };

        try {
            const response = await axios.get(`${url}/admin/price-list-configurator/data-list`, {
                params: data,
            });

            const responseData = response.data.items;
            const priceList = responseData[0] ?? null;

            if (!priceList) return null;

            priceList.Settings = JSON.parse(priceList.Settings);

            return priceList;
        } catch (error) {
            console.error('Error loading data from server:', error);
            throw error;
        }
    };
}