<script setup lang="ts">
import {computed, onMounted, onUnmounted, reactive, ref, watch} from "vue";
    import HotTable from "@handsontable/vue3";
    import { registerAllModules } from 'handsontable/registry';
    import 'handsontable/dist/handsontable.full.css';
    import Handsontable from "handsontable";
    import HandsontableManager from "./../../js/Handsontable";
    import {Table} from "@project/Excel/Handsontable/js/Table.js";
    import FormulaBar from './FormulaBar.vue';

    const props = defineProps<{
        name: string,
        table: Table
    }>();

    registerAllModules();

    const table = props.table;

    const hotTableRef = ref(null);
    const hotInstance = reactive({instance: null});

    // watch(() => table.handsontableSettings, (newVal: {[key: string]: any}, oldValue) => {
    //     // for (const key in newVal) {
    //     //     if (newVal.hasOwnProperty(key)) {
    //     //         hotSettings[key] = newVal[key];
    //     //     }
    //     // }
    // }, {deep: true});

    // watchEffect(() => {
    //     console.log('changed');
    //     if(hotInstance.value){
    //         console.log('hotSettings changed', hotSettings);
    //         hotInstance.value.render();
    //     }
    // });


    onMounted(() => {
        if (hotTableRef.value) {
            // Zde získáte přístup k instanci Handsontable
            //@ts-ignore
            hotInstance.instance = hotTableRef.value.hotInstance;
            table.handsontable = hotInstance.instance;

            if(table.handsontable){
                HandsontableManager.registerInstance(props.name, table);
                //@ts-ignore
                window.table = table;
            }
        }
    });

    onUnmounted(() => {
        HandsontableManager.unregisterInstance(props.name);
    });

    const rowCount = computed(() => {
       return {
           rowCount: table.sheet?.data?.length,
           visualRowCount: table.visualRowCount,
       };
    });

    const selectedRowsCount = ref(null);

    watch(() => table.lastSelectedCells, (newVal) => {
        if(table.handsontable?.getSelected() && newVal){
            const selectedRows = new Set();
            newVal.forEach(row => {
                const startRow = Math.max(Math.min(row[0], row[2]),0); // Začátek range (menší hodnota)
                const endRow = Math.max(row[0], row[2]);   // Konec range (větší hodnota)

                // Přidáme všechny řádky v tomto rozsahu do sady
                for (let i = startRow; i <= endRow; i++) {
                    selectedRows.add(i);
                }
            });
            selectedRowsCount.value = selectedRows.size;
        }else{
            selectedRowsCount.value = null;
        }
    }, {deep: true, immediate: true});
</script>

<template>
    <div>
        <FormulaBar :data-table="table"></FormulaBar>
    </div>
    <hot-table :settings="table.handsontableSettings" ref="hotTableRef"></hot-table>
    <div>
        Počet řádků: {{rowCount.rowCount}}, Zobrazených řádků: {{rowCount.visualRowCount}}, <span v-if="selectedRowsCount">Vybraných řádků: {{selectedRowsCount}}</span>
    </div>
</template>

<style scoped>
    :deep(.htCore thead .sortButton){
        position: absolute;
        left: 4px;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid #bbb;
        padding: 2px;
        color: #bbb;
        border-radius: 3px;
        font-size: 9px;
    }

    :deep(.htCore thead .colHeader){
        padding-left: 10px;
    }

    :deep(.htCore thead .sortButton.active){
        color: rgb(36, 197, 134);
        border-color: rgb(36, 197, 134);
    }

    :deep(.htCore thead .sortButton::after){
        content: "▼ ";
    }

    :deep(.htCore thead .sortButton.asc::after){
        content: "▲ ";
    }

    :deep(.htCore thead .sortButton:hover){
        cursor: pointer;
        color: rgba(36, 197, 134, 0.65);
        border-color: rgba(36, 197, 134, 0.65);
    }

    :deep(.htDimmed){
        color: #373737;;
    }
</style>